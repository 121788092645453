import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./css/privacy.styl"

const PrivacyPolicy = ({ location }) => {

    const intl = useIntl()
    const language = intl.locale

    return (
        <Layout location={location} >
            <SEO
                location={location}
                lang={language}
                title={language !== "ja" ? "Law" : "特定商取引法に基づく表記"}
                keywords={[`Law`, `特定商取引法に基づく表記`]}
            />
            <section className="privacy component">
                <h1>特定商取引法に基づく表記</h1>
                <div className="jp">
                    <p>
                        <strong>事業者の名称</strong><br />
                        加賀谷 雅道
                    </p>
                    <p>
                        <strong>事業者の所在地</strong><br />
                        特定商取引法に基づき、情報開示請求があれば速やかに郵送にてお知らせいたします。請求が必要な方は、コンタクトページから、お名前・ご住所・電話番号を記載の上、ご連絡ください。
                    </p>
                    <p>
                        <strong>事業者の連絡先</strong><br />
                        特定商取引法に基づき、情報開示請求があれば速やかに郵送にてお知らせいたします。請求が必要な方は、コンタクトページから、お名前・ご住所・電話番号を記載の上、ご連絡ください。
                    </p>
                    <p>
                        <strong>販売価格について</strong><br />
                        販売価格は、表示された金額（表示価格/消費税込）と致します。
                    </p>
                    <p>
                        <strong>代金（対価）の支払方法と時期</strong><br />
                        支払方法：クレジットカードによる決済がご利用頂けます。<br />
                        支払時期：商品注文確定時でお支払いが確定致します。
                    </p>
                    <p>
                        <strong>商品の引渡時期</strong><br />
                        配送のご依頼を受けてから8営業日以内に発送いたします。

                    </p>
                    <p>
                        <strong>返品についての特約に関する事項</strong><br />
                        商品に欠陥がある場合を除き、基本的には返品には応じません。
                    </p>
                </div>
                {/* {language === 'en' && <div className="en">
                    <p>
                        <strong>1. Autoradiograph App will never use any privacy information like below.</strong>
                    </p>
                    <p>
                        Location information<br />
                        Image or any other media file storage<br />
                        Camera<br />
                        Advertising ID<br />
                        Device information
                    </p>
                    <p>
                        <strong>2. Copyright of all Sentences/Images/Movies in this App</strong>
                    </p>
                    <p>
                        All autoradiographs, images, and videos in this app are the property of Masamichi Kagaya and Satoshi Mori. Reproduction (including but not limited to screenshots), use, and distribution of any of the content contained this app is strictly prohibited without the express written consent of Masamichi Kagaya and Satoshi Mori.
                    </p>
                </div>} */}
            </section>
        </Layout >
    )
}

export default PrivacyPolicy

